import React from "react";
import { Stack, HStack, Box, Heading, Text } from "@chakra-ui/react";

export default function ThemeColorsExample() {
	// const themeColors = [
	// 	{ group: "Cool Colors", name: "MAC5 Blue", color: "#293b84", height: "4xl" },
	// 	{ group: "Cool Colors", name: "MAC5 Blue", color: "#4a7bc0", height: "3xl" },
	// 	{ group: "Cool Colors", name: "DevOps Teal", color: "#375a20", height: "2xl" },
	// 	{ group: "Cool Colors", name: "PPM Green", color: "#375a20", height: "xl" },
	// 	{ group: "Cool Colors", name: "MAC5 Blue", color: "#75b898", height: "lg" },
	// 	{ group: "Cool Colors", name: "MAC5 Blue", color: "#66a64a", height: "md" },
	// ];

	return (
		<Stack spacing={8}>
			<Stack>
				<Heading color="#293b84" letterSpacing={2}>
					Cool Colors
				</Heading>
				<HStack spacing={0} w="full" align="flex-start">
					<Box bg="#293b84" w="container.lg" h={"4xl"} p="2">
						<Heading color="white" fontWeight="bold" letterSpacing={1} pb={4}>
							#293b84
						</Heading>
						<Text fontSize="xl" color="white" fontWeight="semibold" letterSpacing={2}>
							White Text
						</Text>

						<Text fontSize="xl" color="black" fontWeight="semibold" letterSpacing={2}>
							Black Text
						</Text>

						{/* <Text fontWeight="semibold">Data Driven</Text> */}
					</Box>
					<Box bg="#4a7bc0" w="container.lg" h={"3xl"} color="white" fontWeight="bold" p="2">
						<Heading color="white" fontWeight="bold" letterSpacing={1} pb={4}>
							#4a7bc0
						</Heading>
						<Text fontSize="xl" color="white" fontWeight="semibold" letterSpacing={2}>
							White Text
						</Text>

						<Text fontSize="xl" color="black" fontWeight="semibold" letterSpacing={2}>
							Black Text
						</Text>
						{/* <Text fontWeight="semibold">Relentlessly Resourceful</Text> */}
					</Box>
					<Box bg="teal.700" w="container.lg" h={"3xl"} color="white" fontWeight="bold" p="2">
						<Heading color="white" fontWeight="bold" letterSpacing={1} pb={4}>
							teal.700
						</Heading>
						<Text fontSize="xl" color="white" fontWeight="semibold" letterSpacing={2}>
							White Text
						</Text>
						<Text fontSize="xl" color="black" fontWeight="semibold" letterSpacing={2}>
							Black Text
						</Text>
						{/* <Text fontWeight="semibold">Relentlessly Resourceful</Text> */}
					</Box>
					<Box bg="#375a20" w="container.lg" h={"3xl"} color="white" fontWeight="bold" p="2">
						<Stack pb={4} spacing={0}>
							<Heading color="white" fontWeight="bold" letterSpacing={1}>
								#375a20
							</Heading>
							<Text fontWeight="semibold" letterSpacing={1}>
								PPM Green
							</Text>
						</Stack>
						<Text fontSize="xl" color="white" fontWeight="semibold" letterSpacing={2}>
							White Text
						</Text>
						<Text fontSize="xl" color="black" fontWeight="semibold" letterSpacing={2}>
							Black Text
						</Text>
					</Box>

					<Box bg="#75b898" w="container.lg" h={"2xl"} color="white" fontWeight="bold" p="2">
						<Heading color="white" fontWeight="bold" letterSpacing={1} pb={4}>
							#75b898
						</Heading>
						<Text fontSize="xl" color="white" fontWeight="semibold" letterSpacing={2}>
							White Text
						</Text>

						<Text fontSize="xl" color="black" fontWeight="semibold" letterSpacing={2}>
							Black Text
						</Text>
						{/* <Text fontWeight="semibold">Customer Delight</Text> */}
					</Box>
					<Box bg="#66a64a" w="container.lg" h={"xl"} color="white" fontWeight="bold" p="2">
						<Heading color="white" fontWeight="bold" letterSpacing={1} pb={4}>
							#66a64a
						</Heading>
						<Text fontSize="xl" color="white" fontWeight="semibold" letterSpacing={2}>
							White Text
						</Text>

						<Text fontSize="xl" color="black" fontWeight="semibold" letterSpacing={2}>
							Black Text
						</Text>
						{/* <Text fontWeight="semibold">Happy to Help</Text> */}
					</Box>
				</HStack>
			</Stack>
			<Stack>
				<Heading color="#d62828" letterSpacing={2}>
					Warm Colors
				</Heading>
				<HStack spacing={0} w="full" align="flex-start">
					<Box bg="#d62828" w="container.lg" h={"4xl"} color="white" fontWeight="bold" p="2">
						<Heading color="white" fontWeight="bold" letterSpacing={1} pb={4}>
							#d62828
						</Heading>
						<Text fontSize="xl" color="white" fontWeight="semibold" letterSpacing={2}>
							White Text
						</Text>

						<Text fontSize="xl" color="black" fontWeight="semibold" letterSpacing={2}>
							Black Text
						</Text>
						{/* <Text fontWeight="semibold">Data Driven</Text> */}
					</Box>
					<Box bg="#f77f00" w="container.lg" h={"3xl"} color="white" fontWeight="bold" p="2">
						<Heading color="white" fontWeight="bold" letterSpacing={1} pb={4}>
							#f77f00
						</Heading>
						<Text fontSize="xl" color="white" fontWeight="semibold" letterSpacing={2}>
							White Text
						</Text>

						<Text fontSize="xl" color="black" fontWeight="semibold" letterSpacing={2}>
							Black Text
						</Text>
						{/* <Text fontWeight="semibold">Relentlessly Resourceful</Text> */}
					</Box>
					<Box bg="#fcbf49" w="container.lg" h={"2xl"} color="white" fontWeight="bold" p="2">
						<Heading color="white" fontWeight="bold" letterSpacing={1} pb={4}>
							#fcbf49
						</Heading>
						<Text fontSize="xl" color="white" fontWeight="semibold" letterSpacing={2}>
							White Text
						</Text>

						<Text fontSize="xl" color="black" fontWeight="semibold" letterSpacing={2}>
							Black Text
						</Text>
						{/* <Text fontWeight="semibold">Customer Delight</Text> */}
					</Box>
					<Box bg="#C6AE96" w="container.lg" h={"xl"} color="white" fontWeight="bold" p="2">
						<Heading color="white" fontWeight="bold" letterSpacing={1} pb={4}>
							#C6AE96
						</Heading>
						<Text fontSize="xl" color="white" fontWeight="semibold" letterSpacing={2}>
							White Text
						</Text>

						<Text fontSize="xl" color="black" fontWeight="semibold" letterSpacing={2}>
							Black Text
						</Text>
						{/* <Text fontWeight="semibold">Happy to Help</Text> */}
					</Box>
				</HStack>
			</Stack>
		</Stack>
	);
}
