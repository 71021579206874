import { portalUser } from "../../../App";
import { log } from "../../../helperFunctions";

export default class FormQuestion {
	constructor(question) {
		if (question instanceof FormQuestion) {
			Object.assign(this, question);
		} else {
			this.queryData = question?.queryData ?? "FormQuestion";

			this.templateUID = question?.templateUID ?? null; //This is the FormUID
			this.sectionUID = question?.sectionUID ?? null;
			this.questionUID = question?.questionUID ?? null;

			//Integer
			this.keyID = question?.keyID ?? null;
			this.order = question?.order ?? null;

			//String
			this.heading = question?.heading ?? null;
			this.subheading = question?.subheading ?? null;
			this.inputType = question?.inputType ?? null;
			this.inputFormat = question?.inputFormat ?? null;
		}
	}

	validate(formUID = null) {
		let errors = [];
		if (Boolean(formUID)) {
			let form = portalUser?.formData?.forms?.find((d) => d.formUID === formUID);
			let inputs =
				portalUser.formData?.inputs?.filter(
					(d) => d.formUID === formUID && d.questionUID === this.questionUID
				) ?? [];
			let inputUIDs = inputs.map((d) => d.inputUID) ?? [];
			let comments =
				portalUser.formData?.comments?.filter(
					(d) => d.formUID === formUID && inputUIDs?.includes(d.inputUID)
				) ?? [];

			//logic for question type
			for (let i = 0; i < inputs?.length; i++) {
				let error = inputs[i].validate();
				if (Boolean(error)) {
					errors.push({
						...error,
						message: `This field is required. Please go back and add at least one answer for the ${this.heading} question.`,
					});
				}
			}

			for (let i = 0; i < comments?.length; i++) {
				let error = comments[i].validate();
				if (Boolean(error)) {
					errors.push({
						...error,
						message: `Comments cannot be blank. Please delete the comment or go back and add a comment for the ${this.heading} question.`,
					});
				}
			}

			if (form.formType === "2" && this.inputType === "radio") {
				let selectedCount = inputs?.filter((d) => d.value?.toString() === "1")?.length ?? 0;
				if (selectedCount !== 1) {
					errors.push({
						...inputs[0],
						message: `Please select an answer for the ${this.heading} question.`,
					});
				}
			}

			//Overall performance is a temp fix. Sometimes it says that question isn't answered when it is
			if (this.heading === "Employee Feedback" || this.heading === "Overall Performance") {
				return null;
			} else if (errors?.length > 0 && this.inputType !== "listText") {
				return errors;
			} else if (errors?.length === inputs?.length && this.inputType === "listText") {
				return errors;
			} else if (this.inputType === "radio" && errors?.length > 0) {
				return errors;
			} else if (this.inputType === "rating" && form.formType === "2" && inputs?.length > 0) {
				if (inputs[0].value === "1") {
					let commentCount =
						comments?.filter(
							(d) =>
								d.inputUID === inputs[0]?.inputUID &&
								form?.assignedTo === d?.employeeUID &&
								portalUser.user.employeeUID === d.employeeUID &&
								Boolean(d.keyID) &&
								Boolean(d.value) &&
								d.value?.trim() !== ""
						)?.length ?? 0;

					if (commentCount === 0) {
						errors.push({
							...inputs[0],
							message: `This selection requires a comment. Please add a comment for the ${
								this.heading + ": " + inputs[0]?.description
							} rating.`,
						});
						return errors;
					} else {
						return null;
					}
				}
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
}
